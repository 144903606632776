import { Language } from '@polysky-libs/uikit'


export const EN: Language = { locale: 'en-US', language: 'English', code: 'en' }


export const languages = {
  
  'en-US': EN,

 }

export const languageList = Object.values(languages)
